<script lang="ts" setup>
import {
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogRoot,
  AlertDialogTitle,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogOverlay,
  DialogPortal,
  DialogRoot,
  DialogTitle,
} from '#components';

const props = withDefaults(
  defineProps<{
    containerClass?: string;
    fullscreen?: boolean;
    zIndex?: number;
    ariaTitle?: string;
    ariaDescribedby?: string;
    type?: 'alert' | 'dialog';
    dataTestId?: string;
  }>(),
  { zIndex: 100, type: 'dialog' },
);
const visible = defineModel<boolean>('visible', {
  required: true,
});
defineSlots<{
  default: () => VNode[];
}>();

const { t } = useI18n();

const ModalRoot = props.type === 'alert' ? AlertDialogRoot : DialogRoot;
const ModalPortal = props.type === 'alert' ? AlertDialogPortal : DialogPortal;
const ModalOverlay =
  props.type === 'alert' ? AlertDialogOverlay : DialogOverlay;
const ModalContent =
  props.type === 'alert' ? AlertDialogContent : DialogContent;
const ModalTitle = props.type === 'alert' ? AlertDialogTitle : DialogTitle;
const ModalDescription =
  props.type === 'alert' ? AlertDialogDescription : DialogDescription;
</script>

<template>
  <ModalRoot v-model:open="visible">
    <ModalPortal to="#modals">
      <Transition name="modal">
        <div
          v-if="visible"
          class="baseModal"
          :class="{ baseModal_fullscreen: fullscreen }"
          :style="{ zIndex: zIndex }"
          :data-test-id="dataTestId"
        >
          <ModalOverlay class="baseModal__overlay" />
          <ModalContent
            class="baseModal__content"
            :class="containerClass"
            :data-test-id="`${dataTestId}-content`"
          >
            <VisuallyHidden>
              <ModalTitle>{{ ariaTitle }}</ModalTitle>
              <ModalDescription>{{ ariaDescribedby }}</ModalDescription>
            </VisuallyHidden>

            <slot />

            <DialogClose
              v-if="type === 'dialog'"
              class="baseModal__close"
              :aria-label="t('close')"
            >
              <SvgoSimpleClose />
            </DialogClose>
          </ModalContent>
        </div>
      </Transition>
    </ModalPortal>
  </ModalRoot>
</template>

<i18n>
ru:
  close: Закрыть

en:
  close: Close
</i18n>

<style lang="scss">
.baseModal {
  $self: &;

  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 145px 0;

  @include mq('sm') {
    padding: 0;
  }

  &_fullscreen {
    overflow-y: hidden;
    padding: 0;

    #{$self}__content {
      width: 100%;
      height: 100%;
    }
  }

  &__overlay {
    background: #121212;
    opacity: 0.4;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &__content {
    margin: 0 auto;
    background: #ffffff;
    width: fit-content;
    position: relative;

    @include mq('sm') {
      min-height: 100%;
      max-width: 100% !important;
      width: 100%;
    }
  }

  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 36px;
    height: 36px;
    padding: 0;
    z-index: 1;
    background: none;
    border: none;
    transition: opacity 0.3s ease;
    cursor: pointer;

    &:hover {
      opacity: 0.4;
    }

    svg {
      width: 100%;
      height: 100%;
      margin: 0;
      display: block;
    }
  }

  &.modal-enter-active,
  &.modal-leave-active {
    transition: opacity 0.5s ease;

    #{$self}__container {
      transition: transform 0.5s ease;
    }
  }

  &.modal-enter-from,
  &.modal-leave-to {
    opacity: 0;

    #{$self}__container {
      transform: translateY(100px);
    }
  }
}
</style>
